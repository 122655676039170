@charset "UTF-8";
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
.qpac.video {
  position: relative;
}
.aspect-box .qpac.video {
  position: static;
}
.qpac.video video {
  display: block;
  width: 100%;
}
.qpac.video .video__control {
  transition: all 0.3s ease-in-out;
  position: absolute;
  z-index: 2;
  bottom: 0;
  display: flex;
  width: 9rem;
  align-items: center;
  padding: 0.875rem 1.1875rem 0.875rem 0.875rem;
  background-color: #16171A;
  color: #fff;
  font-size: 0.75rem;
  gap: 1rem;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.video .video__control.is-paused {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.375rem 0.5rem;
  border-radius: 0.1875rem;
  margin: 0.5rem 0.625rem;
  gap: 0.4375rem;
}
.qpac.video .video__control.is-paused .video__progress {
  display: none;
}
.qpac.video .video__control.is-paused .video__duration {
  display: inline;
}
.qpac.video .video__duration {
  display: none;
}
.qpac.video .video__button {
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  transform: scale(1);
  will-change: transform;
}
.qpac.video .video__button:hover {
  transform: scale(1.2);
}
.qpac.video .video__button svg {
  position: relative;
}
.qpac.video .video__pause {
  display: none;
}
.qpac.video .video__progress {
  width: 100%;
  height: 0.0625rem;
  background-color: #4C515C;
}
.qpac.video .video__progress span {
  display: block;
  width: 0;
  height: 100%;
  background-color: #fff;
  font-size: 0;
  line-height: 0;
  transition: width 0.1s linear;
}

.qpac.icon-tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5625rem 0.375rem;
  border: 0.0625rem solid #8F9091;
  border-radius: 0.25rem;
  gap: clamp(0.375rem, 0.37594vw + 0.286654375rem, 0.625rem);
}
@media screen and (min-width:991px) {
  .qpac.icon-tag {
    padding: 0.4375rem 0.625rem 0.375rem 0.5625rem;
  }
}
.qpac.icon-tag h2 {
  margin: 0;
  color: #00FFAE;
  font-size: clamp(0.625rem, 0.18797vw + 0.580826875rem, 0.75rem);
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.icon-tag svg {
  width: 0.875rem;
  height: 0.875rem;
}

section.section.section--story-media {
  padding-bottom: 4.375rem;
}
@media screen and (min-width:991px) {
  section.section.section--story-media {
    padding: 2.5rem 0;
  }
}
section.section.section--story-media:not(:has(.story-media--green)) {
  position: relative;
}
section.section.section--story-media:not(:has(.story-media--green))::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 2rem);
  height: 1px;
  background-color: #8F9091;
  content: "";
  opacity: 1;
  transform: translateX(-50%);
}
@media screen and (min-width:991px) {
  section.section.section--story-media:not(:has(.story-media--green))::after {
    width: calc(100% - 2.5rem);
  }
}
section.section.section--story-media:has(.story-media--green) {
  overflow: hidden;
  padding-top: 5.875rem;
  padding-bottom: 0;
}
section.section.section--story-media:has(.story-media--green) .l-btn {
  border-color: #fff;
}
section.section.section--story-media:has(.story-media--green) .l-btn:hover {
  border-color: #000;
  color: #fff;
}
section.section.section--story-media:has(.story-media--green) .l-btn:hover::after {
  color: #fff;
}
section.section.section--story-media.section--story-grey {
  background-color: #DCDCDC;
}
section.section.section--story-media.section--story-grey .qpac.story-media p {
  color: #16171A;
}
section.section.section--story-media.section--story-media-resource {
  padding: 1.25rem 0 0;
}
section.section.section--story-media.section--story-media-resource::after {
  content: none;
}

@media screen and (min-width:991px) {
  .qpac.story-media {
    display: grid;
    gap: 0 9.775%;
    grid-template-columns: 57.75% auto;
    grid-template-rows: auto;
  }
}
.qpac.story-media .aspect-box {
  --aspect-ratio: 1/1;
}
.qpac.story-media .icon-tag {
  margin-bottom: clamp(1.5rem, 0.75188vw + 1.323308125rem, 2rem);
}
.qpac.story-media .l-tag {
  margin-bottom: 2.0625rem;
}
.qpac.story-media header,
.qpac.story-media article {
  padding: 2.5rem 1rem;
}
@media screen and (min-width:991px) {
  .qpac.story-media header,
  .qpac.story-media article {
    padding: 0;
  }
}
.qpac.story-media header {
  padding-top: 1.5rem;
  grid-column: 2/3;
}
@media screen and (min-width:1024px) {
  .qpac.story-media header {
    padding-top: 1.6875rem;
  }
}
.qpac.story-media header p {
  margin-top: 0.9375rem;
  font-size: 1.0625rem;
  letter-spacing: -0.031875rem;
  line-height: 135%;
  opacity: 0.9;
}
@media screen and (min-width:1320px) {
  .qpac.story-media header p {
    padding-right: 3.125rem;
  }
}
.qpac.story-media article {
  align-self: end;
  padding-bottom: 2.0625rem;
}
@media screen and (min-width:1024px) {
  .qpac.story-media article {
    padding-bottom: 1.3125rem;
  }
}
.qpac.story-media figure {
  grid-column: 1/2;
  grid-row: 1/3;
}
.qpac.story-media .story-media__date {
  margin-bottom: 0.5625rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 120%;
  opacity: 1;
  text-transform: uppercase;
}
@media screen and (min-width:1440px) {
  .qpac.story-media .story-media__date {
    font-size: 0.8333333333vw;
  }
}
.qpac.story-media p {
  color: #fff;
  opacity: 0.9;
}
@media screen and (min-width:1024px) {
  .qpac.story-media p {
    padding-right: 2.125rem;
  }
}
.qpac.story-media ul,
.qpac.story-media ol {
  margin-bottom: 1.5rem;
}
.qpac.story-media li {
  position: relative;
  padding-left: 0.9375rem;
  color: #00FFAE;
  font-size: 0.75rem;
  line-height: 120%;
  text-transform: uppercase;
}
@media screen and (min-width:991px) {
  .qpac.story-media li {
    font-size: 0.8125rem;
  }
}
.qpac.story-media li:has(+ li) {
  margin-bottom: 0.375rem;
}
@media screen and (min-width:991px) {
  .qpac.story-media li:has(+ li) {
    margin-bottom: 0.4375rem;
  }
}
.qpac.story-media li::before {
  position: absolute;
  left: 0;
  content: "•";
}
.qpac.story-media ol li {
  counter-increment: section;
}
.qpac.story-media ol li::before {
  content: counter(section);
}
.qpac.story-media blockquote {
  margin-bottom: 1.5rem;
}
.qpac.story-media blockquote p {
  position: relative;
  color: #00FFAE;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.story-media blockquote p::after, .qpac.story-media blockquote p::before {
  content: "“";
}
.qpac.story-media blockquote p::before {
  position: absolute;
  left: -0.8125rem;
}
@media screen and (min-width:1320px) {
  .qpac.story-media blockquote p {
    padding-right: 4.1875rem;
  }
}
.qpac.story-media blockquote + p {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.story-media .l-btn {
  margin-top: 2rem;
}
.qpac.story-media--inverse {
  grid-template-columns: auto 57.75%;
}
@media screen and (min-width:991px) {
  .qpac.story-media--inverse .icon-tag {
    margin-bottom: 2.125rem;
  }
}
.qpac.story-media--inverse header {
  grid-column: 1/2;
}
@media screen and (min-width:1024px) {
  .qpac.story-media--inverse header {
    padding-top: 1.5rem;
  }
}
.qpac.story-media--inverse figure {
  grid-column: 2/3;
  grid-row: 1/3;
}
.qpac.story-media--green {
  padding-bottom: 1.5rem;
  background-color: #00FFAE;
  color: #1C1D21;
}
@media screen and (min-width:991px) {
  .qpac.story-media--green {
    padding-bottom: 0;
    padding-left: clamp(0.75rem, 2.63158vw + 0.13157875rem, 2.5rem);
    grid-template-columns: auto 56.55%;
  }
}
.qpac.story-media--green .l-tag {
  margin-bottom: 2.1875rem;
  font-size: clamp(0.625rem, 0.18797vw + 0.580826875rem, 0.75rem);
}
@media screen and (min-width:991px) {
  .qpac.story-media--green .l-tag {
    margin-bottom: 2.0625rem;
  }
}
.qpac.story-media--green header {
  padding-top: 2.5rem;
  padding-bottom: 1.1875rem;
}
@media screen and (min-width:991px) {
  .qpac.story-media--green header {
    padding-top: 2.25rem;
    padding-left: 0.1875rem;
  }
}
.qpac.story-media--green header .l-title {
  color: #16171A;
  letter-spacing: normal;
}
@media screen and (min-width:991px) {
  .qpac.story-media--green header .l-title {
    letter-spacing: -0.02625rem;
  }
}
.qpac.story-media--green header .l-title br {
  display: none;
}
@media screen and (min-width:991px) {
  .qpac.story-media--green header .l-title br {
    display: unset;
  }
}
.qpac.story-media--green article li {
  color: #1C1D21;
}
.qpac.story-media--green article p {
  color: #1C1D21;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.0175rem;
  text-transform: uppercase;
}
@media screen and (min-width:991px) {
  .qpac.story-media--green article p {
    padding-right: 5.5rem;
  }
}
@media screen and (min-width:991px) {
  .qpac.story-media--green article {
    padding-bottom: 2.5rem;
  }
}
.qpac.story-media--green figure::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: #1C1D21;
  content: "";
}
.qpac.story-media--green figure.aspect-box {
  --aspect-ratio: 349 / 375;
}
@media screen and (min-width:991px) {
  .qpac.story-media--green figure.aspect-box {
    --aspect-ratio: 714 / 767;
  }
}
.qpac.story-media--grey {
  color: #16171A;
}
.qpac.story-media--grey .l-title {
  color: #16171A;
}
@media screen and (min-width:1024px) {
  .qpac.story-media--grey article {
    padding-bottom: 1.5rem;
  }
}
.qpac.story-media--grey .l-btn {
  margin-top: 2.5rem;
}
.qpac.story-media--resource header {
  padding-top: 1.5625rem;
}
.qpac.story-media--resource header .l-title {
  max-width: 25.6875rem;
}
@media screen and (min-width:1024px) {
  .qpac.story-media--resource article {
    padding-bottom: 1.4375rem;
  }
}
@media screen and (min-width:991px) {
  .qpac.story-media--resource article p {
    padding-right: 3.375rem;
  }
}
@media screen and (min-width:991px) {
  .qpac.story-media--resource .l-btn {
    margin-top: 2.4375rem;
  }
}

@media screen and (min-width:991px) {
  .section--serial-tag + .section.section--story-media {
    padding-top: 0;
  }
}